import React from 'react';
import Layout from '../components/layout';
import GetFree from '../components/get-free/getFree';
import Plans from '../components/plans/Plans';

function Pricing() {
  return (
    <Layout pageTitle='Pricing Page'>
      <Plans />
      <GetFree />
    </Layout>
  );
}

export default Pricing;
