import { Link } from 'gatsby';
import React from 'react';
import Tickicon from '../icons/tickicon';
import XIcon from '../icons/xicon';
import {
  plansSection,
  plansTable,
  tickIcon,
  xIcon,
  price,
} from './plans.module.scss';

function Plans() {
  return (
    <div className={plansSection}>
      <h2>We are offering growth plan free for 6 months</h2>
      <table className={plansTable}>
        <thead>
          <tr>
            <th>List of Features</th>
            <th>
              <div className={price}>Free</div>
              <div>Starter</div>
            </th>
            <th>
              <div className={price}>$0.99</div>
              <div>Growth</div>
              <div className={price}>/user/month</div>
            </th>
            <th>
              <div className={price}>$1.99</div>
              <div>Enterprise</div>
              <div className={price}>/user/month</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Data Retention</td>
            <td>3 months</td>
            <td>3 months</td>
            <td>custom</td>
          </tr>
          <tr>
            <td>Max users</td>
            <td>100</td>
            <td>unlimited</td>
            <td>unlimited</td>
          </tr>
          <tr>
            <td>1-1 Secure Messaging</td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Messaging on patient channel</td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Send Pictures</td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Send Urgent Message</td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Hospital Directory</td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Search provider by patients</td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Message read and delivery status</td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Typing Indicator</td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Chat History</td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Discharge Planning</td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Write Plan of the Day</td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Hand Over tool </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Notes taking</td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>To do list</td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>

          <tr>
            <td>Personal work schedule</td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Department work schedule</td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Personal Events</td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Rotation schedule</td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Nursing shift schedule</td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>On call doc schedule</td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Service team Schedule</td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Consultat Work Schedule</td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Live Care team</td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Analytics Dashboard</td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Dedicated Server</td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>Host own your own premises</td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
          <tr>
            <td>24x7 Great Support</td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <XIcon className={xIcon} />
            </td>
            <td>
              <Tickicon className={tickIcon} />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td>
              <Link to='/' className='button button-green'>
                Signup
              </Link>
            </td>
            <td>
              <Link to='/' className='button button-indigo'>
                Signup for free
              </Link>
            </td>
            <td>
              <Link to='/' className='button button-green'>
                Signup
              </Link>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default Plans;
